import React from "react";
import qs from "qs";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { nishikigoiApi, url_api } from "../../RequestAPI";
import Moment from "moment";
import { ButtonDefault } from "../../components/Form";
import Navbar from "../../components/Navbar";
import { PopUpDefault } from "../../components/Modal";

export class FishGallery extends React.Component {
    constructor(){
        super();
        this.state = {
            data: [],
            loading: false,
            showPopUp : false,
            popupContent : '',
            dataById : null,
        }
    }

    componentDidMount(){
        this.getData();
    }

    getData(){
        const parameter = {
            action: "get_koi_for_sale",
            class: "all",
        };
        nishikigoiApi.post('/fish_gallery.php', qs.stringify(parameter))
        .then((res) => {
            this.setState({
                data: res.data.data
            })
        }, (error) => {
          console.log(error)
        });
    }

    onAction = (e, content, data) => {
        this.setState({
            showPopUp: true,
            popupContent: content,
            dataById: data
        })
    }

    onCloseAction = (e) => {
        this.setState({
            showPopUp: false,
        })
        this.getData();
    }
    
    render(){
        const {data,loading} = this.state;
        const {showPopUp, popupContent, dataById} = this.state;
        const header_green = {
            backgroundColor: '#545161',
            borderColor: '#ffffff',
            color: '#fff',
            fontSize: '14px'
        }
        const row = {
            fontSize: '13px',
            backgroundColor: '#D3D3D3',
            borderColor: '#ffffff',
            verticalAlign: 'text-top'
        }

        return(
            <React.Fragment>
                <Navbar />
                    <Sidebar />
                    <div className="main-container">
                    <Header />
                    <div className="pd-ltr-20">
                        <ButtonDefault onClick={((e) => this.onAction(e, "form_add_gallery",dataById))} class="btn btn-small btn-secondary mb-2" text="+ Add Data" />
                        {loading ? null : (
                            <div className="row">
                            <div className="col">
                            <div className="table-responsive">
                                <table className="table table-bordered table-sm" id="">
                                    <thead>
                                        <tr>
                                        <th className="align-middle text-center p-0" style={header_green}>ID</th>
                                        <th className="align-middle text-center p-0" style={header_green}>Variety</th>
                                        <th className="align-middle text-center p-0" style={header_green}>Classification</th>
                                        <th className="align-middle text-center p-0" style={header_green}>Farm</th>
                                        <th className="align-middle text-center p-0" style={header_green}>url</th>
                                        <th className="align-middle text-center p-0" style={header_green}>Desc</th>
                                        <th className="align-middle text-center p-2" style={header_green}>Status</th>
                                        <th className="align-middle text-center p-2" style={header_green}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.length !== 0
                                        ?
                                        data.map((data, index) => {
                                            return (
                                            <tr key={index}>
                                                <td style={row}>{data.fish_id}</td>
                                                <td style={row}>{data.variety}</td>
                                                <td style={row}>{data.classification}</td>
                                                <td style={row}>{data.farm}</td>
                                                <td style={row} className="text-nowrap">img: <a href={`${url_api}/${data.url_img}`}>{data.url_img}</a><br/>video: <a href={`${url_api}/${data.url_video}`}>{data.url_video}</a></td>
                                                <td style={row}>{`DOB: ${Moment(data.dob).format('YYYY-MM-DD')} size: ${data.size} age: ${data.age} gender: ${data.gender} upload date : ${data.upload_date}`}</td>
                                                <td style={row} className="text-nowrap">IDR {data.price}<br/>{data.status}</td>
                                                <td style={row} className="text-nowrap">
                                                    <center>
                                                        <button type="button" className="btn btn-link text-primary p-0 mr-2"><i class="icon-copy dw dw-edit"></i></button>
                                                        <button type="button" className="btn btn-link text-danger p-0 mr-2"><i class="icon-copy dw dw-delete-3"></i></button>
                                                    </center>
                                                </td>
                                            </tr>
                                            );
                                        })
                                        : null
                                        }
                                    </tbody>
                                    </table>
                                    </div>
                            </div>
                            </div>
                        )}
                    </div>
                    <PopUpDefault
                        show={showPopUp}
                        onAction={this.onAction}
                        onClose={this.onCloseAction}
                        content={popupContent}
                        data={dataById} />
                </div>
            </React.Fragment>
        );
    }
}

export default FishGallery