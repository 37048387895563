import React from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Navbar from '../components/Navbar';
import { UnderConstruction } from "../components/Information";

export function PageUnderConstruction() {
    
    return (
        <React.Fragment>
          <Navbar />
          <Sidebar />
          <div className="main-container">
            <Header />
            <div className="pd-ltr-20 mt-5">
                <UnderConstruction />
            </div>
          </div>
        </React.Fragment>
      );
};

export default PageUnderConstruction;
