import React from 'react';
import logo from '../images/sierra-moro.png';
import {Link} from 'react-router-dom';
import menu_koi_for_sale from '../local-data/menu_koi_for_sale.json';
import menu_online_auction from '../local-data/menu_online_auction.json';
import menu_membership from '../local-data/menu_membership.json';
import menu_administration from '../local-data/menu_administration.json';

export function Sidebar() {
    return (
        <div className="left-side-bar">
            <div className="brand-logo">
                <a href="index.html">
                    <img src={logo} alt="" className="light-logo" />
                </a>
                <div className="close-sidebar" data-toggle="left-sidebar-close">
                    <i className="ion-close-round"></i>
                </div>
            </div>
            <div className="menu-block customscroll">
                <div className="sidebar-menu">
                    <ul id="accordion-menu">

                        <li>
                            <div className="sidebar-small-cap">KOI for SALE</div>
                        </li>

                        {
                            menu_koi_for_sale.map((data, index) => {
                                return (
                                    <li key={index}>
                                        <Link to={data.path} className="dropdown-toggle no-arrow">
                                            <span className={`micon icon-copy ion-ios-minus-empty`}></span><span className="mtext">{data.menu}</span>
                                        </Link>
                                    </li>
                                );
                            })
                        }

                        <li>
                            <div className="sidebar-small-cap">Online AUCTION</div>
                        </li>

                        {
                            menu_online_auction.map((data, index) => {
                                return (
                                    <li key={index}>
                                        <Link to={data.path} className="dropdown-toggle no-arrow">
                                            <span className={`micon icon-copy ion-ios-minus-empty`}></span><span className="mtext">{data.menu}</span>
                                        </Link>
                                    </li>
                                );
                            })
                        }

                        <li>
                            <div className="sidebar-small-cap">Membership</div>
                        </li>

                        {
                            menu_membership.map((data, index) => {
                                return (
                                    <li key={index}>
                                        <Link to={data.path} className="dropdown-toggle no-arrow">
                                            <span className={`micon icon-copy ion-ios-minus-empty`}></span><span className="mtext">{data.menu}</span>
                                        </Link>
                                    </li>
                                );
                            })
                        }

                        <li>
                            <div className="sidebar-small-cap">Administration</div>
                        </li>

                        {
                            menu_administration.map((data, index) => {
                                return (
                                    <li key={index}>
                                        <Link to={data.path} className="dropdown-toggle no-arrow">
                                            <span className={`micon icon-copy ion-ios-minus-empty`}></span><span className="mtext">{data.menu}</span>
                                        </Link>
                                    </li>
                                );
                            })
                        }

                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Sidebar
