import axios from "axios";

export const url_api = "https://restapi.sierra.moro.nishikigoi.cloud";

export const nishikigoiApi = axios.create();
nishikigoiApi.defaults.timeout = 600000;
nishikigoiApi.interceptors.request.use((config) => {
    config.baseURL = url_api
    config.headers = {
        'Access-Control-Allow-Origin': '*',
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
    };
    return config;
},
    (error) => {
        return Promise.reject(error);
    }
);

const exportedObject = {url_api,nishikigoiApi};

export default exportedObject