import header from '../images/header.png';

export const Header = () => {
    const img = {
        height: '150px'
    }
    return(
        <div className="jumbotron p-0 m-0">
            <center>
              <img src={header} style={img} className="img-fluid" alt="..."/>
            </center>
        </div>
    );
}

export default Header