import { Routes, Route } from 'react-router-dom';
import FarmManagement from './pages/farm/FarmManagement';
import FishGallery from './pages/fish_gallery/FishGallery';
import Home from './pages/Home';
import KoiForSale from './pages/KoiForSale';
import PageUnderConstruction from './pages/PageUnderConstruction';

function App() {
    return (
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/koi-for-sale/:class_id' element={<KoiForSale />} />
            <Route path='/fish-gallery' element={<FishGallery />} />
            <Route path='/references' element={<FarmManagement />} />
            <Route path='/under-construction' element={<PageUnderConstruction />} />
        </Routes>
    );
}

export default App;
