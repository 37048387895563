import qs from 'qs';
import React from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { nishikigoiApi, url_api } from "../RequestAPI";
import Moment from 'moment';
import Navbar from '../components/Navbar';
import { ButtonDefault } from '../components/Form';

export class Home extends React.Component {
  constructor(){
    super();
    this.state = {
      data: [],
      loading: true
    }
  }

  componentDidMount(){
    this.getData();
  }

  getData() {
    const parameter = {
      action: 'get_koi_for_sale',
      class: '2'
    };
    nishikigoiApi.post('/fish_gallery.php', qs.stringify(parameter))
      .then((res) => {
        if (res.data !== null) {
          this.setState({
            data: res.data.data,
            loading: false
          })
        } else {
          this.setState({
            data: []
          })
        }
      }, (error) => {
        console.log(error);
      });
  }

  render(){
    const {loading,data} = this.state;
    return (
      <React.Fragment>
        <Navbar />
        <Sidebar />
        <div className="main-container">
          <Header />
          <div className="pd-ltr-20">
            
            {
              loading
              ? null
              :
              <div className="row">
                {
                  data.length === 0
                  ? null
                  : data.map((data,index) => {
                    return(
                      <div key={index} className="col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-4">
                        <GalleryImage data={data} />
                      </div>
                    );
                  }) 
                }
              </div>
            }
          </div>
        </div>
      </React.Fragment>
    );
  }
};

const GalleryImage = (props) => {
  const container_gallery = {
    height: "320px",
    backgroundColor: "#4CA5F4",
    position: "relative",
  };
  const top_left_desc = {
    maxWidth: "90px",
    position: "absolute",
    top: "8px",
    left: "16px",
    fontWeight: "700",
    fontSize: "10px",
    color: "#fff",
  };
  const top_right_desc = {
    maxWidth: "90px",
    position: "absolute",
    top: "8px",
    right: "16px",
    fontWeight: "700",
    fontSize: "10px",
    color: "#fff",
  };
  const img_fish = {
    height: "100%",
    width: "auto",
    objectFit: "contain",
  };
  const data = props.data;
  const img_src = `${url_api}/fish_gallery/${data.url_img}`;
  return (
    <div className="card">
      <div className="card-header text-center">{data.variety}</div>
      <div style={container_gallery}>
        <img style={img_fish} className="mx-auto d-block" src={img_src} alt={data.variety}
        />
        <div style={top_left_desc} className="text-left">
          {`DOB: ${Moment(data.dob).format('YYYY-MM-DD')}`}<br />{data.gender}<br />{data.size}<br />{data.age}
        </div>
        <div style={top_right_desc} className="text-right">
          {data.farm}<br />{data.classification}<br />{data.fish_id}
        </div>
      </div>
      <div className="card-footer bg-white text-center">
        <p><small>{`IDR ${data.price}`}</small></p>
        <ButtonDefault class="btn btn-small btn-success" text="Buy" />
      </div>
    </div>
  );
};

export default Home;
