export function ButtonDefault(props){
    const style_button = {
        fontSize: "12px",
        borderRadius: "10px",
      };
    return(
        <button
          type="button"
          onClick={props.onClick}
          style={style_button}
          className={props.class}
        >
          {props.text}
        </button>
    );
}