import cat from '../images/cat.png';
import web_under_const from "../images/under_const.jpg";
export function EmptyData() {
    const image = {
        height: '280px'
    }
    return (
        <center className="mt-5">
            <img src={cat} style={image} className="img-fluid" alt="Responsive image" />
            <p className="h5">We are sorry, no data found right now</p>
        </center>
    );
}

export function UnderConstruction(){
    const image = {
        height: '200px'
    }
    return(
        <center className="mt-5">
            <img src={web_under_const} style={image} class="img-fluid" alt="Responsive image" />
            <p class="h5">This page is under construction</p>
        </center>
    );
}

const exportedObject = { EmptyData, UnderConstruction };

export default exportedObject