const Navbar = () => {

	return (
		<div className="header">
			<div className="header-left">
				<div className="menu-icon dw dw-menu"></div>
			</div>
			<div className="header-right">
			</div>
		</div>
	);

}

export default Navbar
