import React from "react";
import {Modal} from 'react-bootstrap';
import CloseButton from 'react-bootstrap/CloseButton';

export class PopUpDefault extends React.Component {

    onAction = (e, content, data) => {
      this.props.onAction && this.props.onAction(e, content, data);
    };
  
    onClose = e => {
      this.props.onClose && this.props.onClose(e);
    };
  
    render(){
      let className, align;
      const {content, show, data} = this.props;
      const content_md = ["form_add_gallery"];
      const content_xs = [];
      if (content_md.includes(content)) {
        className = "custom-modal-medium";
        align = "left";
      } else if (content_xs.includes(content)) {
        className = "custom-modal-small";
        align = "left";
      } else {
        className = "custom-modal-small";
        align = "center";
      }
      if (!show) {
        return null;
      }
      return(
        <Modal show={show} onHide={this.onClose} size="lg" dialogClassName={className} align={align} centered backdrop="static">
          <Modal.Body>
            <div class="d-flex justify-content-end"><CloseButton onClick={this.onClose} /></div>
            {
              content === "form_add_gallery"
              ? null
              : null
            }
          </Modal.Body>
        </Modal>
      );
    }
}

const exportedObject = {
    PopUpDefault
};

export default exportedObject