import qs from "qs";
import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { nishikigoiApi, url_api } from "../RequestAPI";
import Moment from "moment";
import { useParams } from "react-router-dom";
import { EmptyData } from "../components/Information";
import { ButtonDefault } from "../components/Form";
import Navbar from "../components/Navbar";

export const KoiForSale = () => {
  let { class_id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, [class_id]);

  const getData = () => {
    const parameter = {
      action: "get_koi_for_sale",
      class: class_id,
    };
    nishikigoiApi.post("/fish_gallery.php", qs.stringify(parameter)).then(
      (res) => {
        if (res.data !== null) {
          setData(res.data.data);
        }
        setLoading(false);
      },
      (error) => {
        console.log(error);
      }
    );
  };
  return (
    <React.Fragment>
      <Navbar />
        <Sidebar />
        <div className="main-container">
          <Header />
          <div className="pd-ltr-20">
            {loading ? null : (
                <div className="row">
                    
                {data.length === 0
                    ? <EmptyData />
                    : 
                    <>
                        <p className="text-center mb-4"><ins>ALL <strong>{class_id === "all" ? "Premium" : data[0].classification}</strong> Koi for sale</ins></p>
                        {
                            data.map((data, index) => {
                                return (
                                <div key={index} className="col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-4">
                                    <GalleryImage data={data} />
                                </div>
                                );
                            })
                        }
                    </>
                    }
                </div>
            )}
        </div>
      </div>
    </React.Fragment>
  );
};

const GalleryImage = (props) => {
  const container_gallery = {
    height: "300px",
    backgroundColor: "#4CA5F4",
    position: "relative",
  };
  const top_left_desc = {
    maxWidth: "90px",
    position: "absolute",
    top: "8px",
    left: "16px",
    fontWeight: "700",
    fontSize: "10px",
    color: "#fff",
  };
  const top_right_desc = {
    maxWidth: "90px",
    position: "absolute",
    top: "8px",
    right: "16px",
    fontWeight: "700",
    fontSize: "10px",
    color: "#fff",
  };
  const img_fish = {
    height: "100%",
    width: "auto",
    objectFit: "contain",
  };
  
  const data = props.data;
  const img_src = `${url_api}/fish_gallery/${data.url_img}`;
  return (
    <div className="card">
      <div className="card-header text-center">{data.variety}</div>
      <div style={container_gallery}>
        <img
          style={img_fish}
          className="mx-auto d-block"
          src={img_src}
          alt={data.variety}
        />
        <div style={top_left_desc} className="text-left">
          {`DOB: ${Moment(data.dob).format("YYYY-MM-DD")}`}
          <br />
          {data.gender}
          <br />
          {data.size}
          <br />
          {data.age}
        </div>
        <div style={top_right_desc} className="text-right">
          {data.farm}
          <br />
          {data.classification}
          <br />
          {data.fish_id}
        </div>
      </div>
      <div className="card-footer bg-white text-center">
        <p>
          <small>{`IDR ${data.price}`}</small>
        </p>
        <ButtonDefault class="btn btn-small btn-success" text="Buy" />
      </div>
    </div>
  );
};

export default KoiForSale;
