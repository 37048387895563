import React from "react";
import qs from "qs";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { nishikigoiApi } from "../../RequestAPI";
import { ButtonDefault } from "../../components/Form";
import Navbar from "../../components/Navbar";

export class FarmManagement extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    const parameter = {
      action: "get_farm",
    };
    nishikigoiApi.post("/farm.php", qs.stringify(parameter)).then(
      (res) => {
        this.setState({
          data: res.data.data,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  render() {
    const { data, loading } = this.state;
    const header_green = {
      backgroundColor: "#545161",
      borderColor: "#ffffff",
      color: "#fff",
      fontSize: "14px",
    };
    const row = {
      fontSize: "13px",
      backgroundColor: "#D3D3D3",
      borderColor: "#ffffff",
      verticalAlign: "text-top",
    };

    return (
      <React.Fragment>
        <Navbar />
        <Sidebar />
        <div className="main-container">
          <Header />
          <div className="pd-ltr-20">
            <ButtonDefault
              class="btn btn-small btn-secondary mb-2"
              text="+ Add Data"
            />
            {loading ? null : (
              <div className="row">
                <div className="col">
                    <div className="table-responsive">
                        <table className="table table-bordered table-sm" id="">
                            <thead>
                            <tr>
                                <th
                                className="align-middle text-center p-0"
                                style={header_green}
                                >
                                No
                                </th>
                                <th
                                className="align-middle text-center p-0"
                                style={header_green}
                                >
                                Farm Breeder Name
                                </th>
                                <th
                                className="align-middle text-center p-0"
                                style={header_green}
                                >
                                Owner
                                </th>
                                <th
                                className="align-middle text-center p-0"
                                style={header_green}
                                >
                                Address
                                </th>
                                <th
                                className="align-middle text-center p-0"
                                style={header_green}
                                >
                                Variety Specialist
                                </th>
                                <th
                                className="align-middle text-center p-2"
                                style={header_green}
                                >
                                Action
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {data.length !== 0
                                ? data.map((data, index) => {
                                    return (
                                    <tr key={index}>
                                        <td style={row}>{index + 1}</td>
                                        <td
                                        style={row}
                                        className="text-nowrap"
                                        >{`${data.farm_name} [${data.farm_location}]`}</td>
                                        <td style={row}>{data.farm_owner}</td>
                                        <td style={row}>{data.farm_addr}</td>
                                        <td style={row}>{data.variety_specialist}</td>
                                        <td style={row} className="text-nowrap">
                                        <center>
                                            <button
                                            type="button"
                                            className="btn btn-link text-primary p-0 mr-2"
                                            >
                                            <i class="icon-copy dw dw-edit"></i>
                                            </button>
                                            <button
                                            type="button"
                                            className="btn btn-link text-danger p-0 mr-2"
                                            >
                                            <i class="icon-copy dw dw-delete-3"></i>
                                            </button>
                                        </center>
                                        </td>
                                    </tr>
                                    );
                                })
                                : null}
                            </tbody>
                        </table>
                    </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default FarmManagement;
